import React, { useState, useEffect, useMemo } from 'react';
import Header from '../../../../../../components/shared/header';
import Card from '../../../../../../components/shared/card';
import { Box, Typography } from '@mui/material';
import Chip from '../../../../../../components/shared/chip';
import OverflowTooltip from '../../../../../../components/shared/tooltip/OverflowTooltip';
import { useTheme } from '@mui/material/styles';
import Dialog from '../../../../../../components/shared/dialog';
import Accordion from '../../../../../../components/shared/accordion';
import Select from '../../../../../../components/shared/form/Select/select';
import Button from '../../../../../../components/shared/buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { DependencyIcon } from '../../../../../../libs/svg-icons/icons';
import Input from '../../../../../../components/shared/form/Input/input';
import MediaPlanSubmission from './MediaPlanSubmission';
import CheckboxSubmission from './CheckboxSubmission';
import ProgressDateComp from '../../ProgressDateComp';
import Comments from '../../../../../../components/shared/comments';
import {
  calculateSubtaskDetailsRequest,
  createUpdateProjectSubmission,
  getProjectStatusList,
  getProjectSubTaskDetails,
  subtaskApproveRejectEditRequest,
  subtaskEditRequest,
  taskApproveRejectRequest,
  updateProjectStatus,
} from '../../../../../../services/project-service';
import { AppConstants } from '../../../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import { capitalize } from '../../../../../../utils/string';
import { getReaminingDaysPercentage } from '../../../../../../utils/project';
import { formatDate } from '../../../../../../libs/date/format';
import Loader from '../../../../../../components/shared/loader';
import ApproveRejectAction from '../../../../../../components/shared/approve-reject';
import SendEditRequest from './SendEditRequest';
import { useAppSelector } from '../../../../../../redux/hooks';
import { handleError } from '../../../../../../utils/errorHandling';
import BackdropOverlay from '../../../../../../components/shared/backdropOverlay';

const SubtaskDetails = () => {
  const theme = useTheme();
  const params = useParams();
  const enqueueSnackbar = useEnqueueSnackbar();
  const navigate = useNavigate();

  const [openDependencyDialog, setOpenDependencyDialog] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [details, setDetails] = useState([]);
  const [SubtaskDetails, setSubTaskDetails] = useState({});
  const [subTaskStatuses, setSubTaskStatuses] = useState([]);

  const [showEditApproveTool, setEditApproveTool] = useState(false);
  const [showApproveTool, setApproveTool] = useState(false);
  const [isCheckBoxForm, setIsCheckBoxForm] = useState(false);
  const [projectSubmissionProgress, setProjectSubmissionProgress] = useState(false);

  const [pageAccess, setPageAccess] = useState(false);

  const projectTeams = ['Buying Team', 'Finance Team', 'Consulting Team', 'Reviewing Team'];

  const userInfo = useAppSelector(state => state.auth);

  const loggedInUserTeams = useMemo(() => {
    return userInfo?.teams?.map(x => x.id);
  }, [userInfo]);

  useEffect(() => {
    fetchProjectStatusList();
  }, []);

  useEffect(() => {
    if (subTaskStatuses.length) {
      fetchTaskDetails();
    }
  }, [subTaskStatuses]);

  const fetchProjectStatusList = async () => {
    setIsloading(true);
    await getProjectStatusList('SUBTASK')
      .then(res => {
        const { data } = res.data;
        const t = data.map(x => x);
        setSubTaskStatuses(t);
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const fetchTaskDetails = async () => {
    setIsloading(true);
    await getProjectSubTaskDetails(params?.subtaskId)
      .then(res => {
        const { data } = res.data;
        formatProjectData(data);
      })
      .catch(error => {
        const { status = '' } = error?.response?.data || {};
        if (status === AppConstants.HTTP_FORBIDDEN) {
          setPageAccess(true);
        }
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
        setProjectSubmissionProgress(false);
      });
  };

  const updateSubTaskStatusCall = async status => {
    setIsloading(true);
    const payload = {
      'entityType': AppConstants.ENTITY_TYPE_SUBTASK,
      'projectId': params?.id,
      'taskId': params?.taskId,
      'subTaskId': params?.subtaskId,
      'status': status,
    };

    await updateProjectStatus(payload)
      .then(res => {
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setSelectedStatus(SubtaskDetails?.status);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    if (
      (selectedStatus === AppConstants.PROJECT_STATUS_COMPLETED &&
        !isCheckBoxForm &&
        isValidationErrors(SubtaskDetails?.projectSubmission)) ||
      SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_IN_PROGRESS
    ) {
      if (selectedStatus !== SubtaskDetails?.status) {
        enqueueSnackbar(AppConstants.GCP_VALIDATION_ERROR_ON_STATUS, 'error');
        setSelectedStatus(SubtaskDetails?.status);
      }
    } else {
      if (SubtaskDetails?.status && SubtaskDetails?.status !== selectedStatus) {
        updateSubTaskStatusCall(selectedStatus);
      }
    }
  }, [selectedStatus]);

  const showApprovalToolbar = (allowedTeams, requestStatus) => {
    let flag = false;

    if (requestStatus === 'IN_PROGRESS') {
      allowedTeams.forEach(team => {
        if ([...loggedInUserTeams].includes(team)) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const formatProjectData = response => {
    const {
      accountableTeam = '-',
      consultingTeam = '-',
      responsibleTeam = '-',
      informedTeam = [],
      task: { duration = '-', descrption = '-', name = '-', tutorialLink = '' },
      status = '',
      startDate = '',
      endDate = '',
      statusModifiedDate = '',
      projectSubmission = [],
      projectSubTaskEditRequest = null,
      dependency = '',
      assignedTeam = [],
      projectApproval = null,
      validationStatus = null,
      projectStatus = '',
      url = '',
      isValidation = false,
      isContinuedWithException = false,
      isTable = false,
      calculationStatus = '',
    } = response;
    const [firstItem = null] = projectSubmission;

    if (firstItem) {
      setIsCheckBoxForm(firstItem?.isCheckbox);
    }

    setDetails([
      {
        name: 'Responsible Team',
        description: responsibleTeam?.length ? responsibleTeam.join(', ') : '-',
      },
      {
        name: 'Consulting Team',
        description: consultingTeam?.length ? consultingTeam.join(', ') : '-',
      },
      { name: 'Timeline', description: `${duration} Days` || '-' },
      {
        name: 'Accountable Team',
        description: accountableTeam?.length ? accountableTeam.join(', ') : '-',
      },
      { name: 'Informed Team', description: informedTeam?.length ? informedTeam.join(', ') : '-' },
    ]);
    setSelectedStatus(status);

    if (projectSubTaskEditRequest) {
      setEditApproveTool(
        showApprovalToolbar(
          projectSubTaskEditRequest?.approverIds,
          projectSubTaskEditRequest?.editRequestStatus,
        ),
      );
    }

    setApproveTool(
      status === 'COMPLETED'
        ? showApprovalToolbar(projectApproval?.approverTeamIds, projectApproval?.requestStatus)
        : false,
    );

    const { progress, remainingDays } = getReaminingDaysPercentage(startDate, endDate);

    setSubTaskDetails({
      ...response,
      descrption,
      name,
      startDate: startDate ? formatDate(startDate, 'do MMMM, yyyy') : '-',
      endDate: endDate ? formatDate(endDate, 'do MMMM, yyyy') : '-',
      statusModifiedDate: statusModifiedDate
        ? formatDate(statusModifiedDate, `do MMMM, yyyy 'at' hh:mm a`)
        : '-',
      progress,
      remainingDays,
      tutorialLink,
      projectSubmission,
      dependency,
      validationStatus,
      assignTeamStr: assignedTeam?.join(', ') || '',
      url: url || '',
      projectStatus: projectStatus,
      isValidate: isValidation,
      isContinuedWithException,
      isTable,
      calculationStatus,
    });
  };

  const sendEditRequestCall = async status => {
    setIsloading(true);
    await subtaskEditRequest(params?.subtaskId, {
      'status': status,
    })
      .then(res => {
        enqueueSnackbar('Edit request sent successfully', 'success');
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const sendEditApproveRequestCall = async status => {
    setIsloading(true);
    await subtaskApproveRejectEditRequest(params?.subtaskId, {
      'requestId': SubtaskDetails?.projectSubTaskEditRequest?.id,
      'editRequestStatus': status,
    })
      .then(res => {
        enqueueSnackbar(
          `Edit request ${status === AppConstants.EDIT_REQUEST_APPROVED ? 'Approved' : 'Rejected'} successfully`,
          'success',
        );
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const createUpdateSubtaskRequest = async (
    payload,
    isContinuedWithException = false,
    url = '',
  ) => {
    setIsloading(true);
    setProjectSubmissionProgress(true);

    let temp = {
      'projectTaskId': parseInt(params?.subtaskId),
      'isContinuedWithException': isContinuedWithException,
      'projectSubmissionDtos': payload,
    };

    if (url) {
      temp['url'] = url;
    }

    await createUpdateProjectSubmission(temp)
      .then(res => {
        enqueueSnackbar('Details updated successfully.', 'success');
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setIsloading(false);
      });
    // .finally(() => {
    //   setIsloading(false);
    // });
  };

  const calculateTableSubtaskDetails = async () => {
    setIsloading(true);
    setProjectSubmissionProgress(true);

    let payload = {
      'projectTaskId': parseInt(params?.subtaskId),
    };

    await calculateSubtaskDetailsRequest(payload)
      .then(res => {
        enqueueSnackbar('Details sent for calcluation successfully.', 'success');
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setIsloading(false);
      });
  };

  const subtaskApprovalRequestCall = async status => {
    setIsloading(true);
    await taskApproveRejectRequest({
      'projectId': parseInt(params?.id),
      'taskId': parseInt(params?.subtaskId),
      'approvalId': SubtaskDetails?.projectApproval?.id,
      'status': status,
    })
      .then(res => {
        enqueueSnackbar(`Request ${capitalize(status)} sucessfully.`, 'success');
        fetchTaskDetails();
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
        setIsloading(false);
      });
  };

  const isValidationErrors = (data = []) => {
    let error = false;
    if (
      SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_VALIDATED ||
      SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_NOT_VALIDATED
    ) {
      data?.forEach(item => {
        const { validationError = null } = item || {};

        if (validationError?.message) {
          if (validationError?.errorType === 'error') {
            error = true;
          }
        }
      });
    }
    return error;
  };

  const showEditRequestButton = useMemo(() => {
    const d = SubtaskDetails?.projectApproval?.requestStatus === 'APPROVED';
    const checkError = isValidationErrors(SubtaskDetails?.projectSubmission || []);

    return (
      (SubtaskDetails?.status === 'COMPLETED' && d && SubtaskDetails?.isView === true) ||
      (SubtaskDetails?.status === 'COMPLETED' && checkError)
    );
  }, [SubtaskDetails]);

  // const showEditMode = useMemo(() => {
  //   const { projectSubTaskEditRequest } = SubtaskDetails;
  //   //validation status to add here once it get in response
  //   return projectSubTaskEditRequest?.editRequestStatus === 'APPROVED';
  // }, [SubtaskDetails]);

  const freezeAll = useMemo(() => {
    return SubtaskDetails?.projectStatus === AppConstants.PROJECT_STATUS_CANCEL;
  }, [SubtaskDetails]);

  const approvalMsg = useMemo(() => {
    let msg = '';
    if (isCheckBoxForm) {
      let isException = !!SubtaskDetails?.projectSubmission?.find(
        item => item.columnValue === 'false',
      );

      msg =
        !SubtaskDetails?.url || isException
          ? AppConstants.SUBTASK_EXCEPTION_APPROVAL_MSG
          : AppConstants.SUBTASK_APPROVAL_MSG;
    } else {
      msg = SubtaskDetails?.isContinuedWithException
        ? AppConstants.SUBTASK_EXCEPTION_APPROVAL_MSG
        : AppConstants.SUBTASK_APPROVAL_MSG;
    }
    return msg;
  }, [SubtaskDetails, isCheckBoxForm]);

  return (
    <>
      <BackdropOverlay
        open={pageAccess}
        pageWarning={AppConstants.PAGE_ACCESS_ERROR}
        buttonLabel="Redirect To Task"
        clickAction={() => {
          navigate(`/app/projects/${params.id}/view-project/${params?.taskId}`);
        }}
        autharizationWarning={true}
      />
      <Loader
        isLoading={isLoading}
        msg={
          projectSubmissionProgress
            ? 'This action may take up to 30 seconds to complete, So please wait......!!!'
            : ''
        }
      />
      <Card sx={{ alignItems: 'flex-start' }}>
        {SubtaskDetails && showEditApproveTool && !freezeAll && (
          <ApproveRejectAction
            title={'Subtask edit request'}
            onApprove={() => {
              sendEditApproveRequestCall(AppConstants.EDIT_REQUEST_APPROVED);
            }}
            onReject={() => {
              sendEditApproveRequestCall(AppConstants.EDIT_REQUEST_REJECTED);
            }}
          />
        )}
        {SubtaskDetails && showApproveTool && !freezeAll && (
          <ApproveRejectAction
            title={approvalMsg}
            onApprove={() => {
              subtaskApprovalRequestCall(AppConstants.EDIT_REQUEST_APPROVED);
            }}
            onReject={() => {
              subtaskApprovalRequestCall(AppConstants.EDIT_REQUEST_REJECTED);
            }}
          />
        )}
        <Header
          breadcrumbs={[{ name: decodeURIComponent(SubtaskDetails?.task?.externalId || '-') }]}
          backHref={`/app/projects/${params.id}/view-project/${params?.taskId}`}
          rightActions={
            <Box sx={{ display: 'flex', gap: '20px' }}>
              {showEditRequestButton && (
                <SendEditRequest
                  statusOptions={subTaskStatuses}
                  sendEditRequestCall={sendEditRequestCall}
                />
              )}

              {SubtaskDetails?.projectApproval?.requestStatus ===
                AppConstants.GCP_STATUS_IN_PROGRESS && (
                <Chip
                  status={''}
                  label={<Typography variant="Medium-14">Approval Pending</Typography>}
                />
              )}
              {!SubtaskDetails?.isTable &&
                SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_IN_PROGRESS && (
                  <Chip
                    status={''}
                    label={<Typography variant="Medium-14">Validation In Progress</Typography>}
                  />
                )}

              {(SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_VALIDATED ||
                SubtaskDetails?.validationStatus === AppConstants.GCP_STATUS_NOT_VALIDATED) && (
                <Chip
                  status={'success'}
                  label={<Typography variant="Medium-14">Validated</Typography>}
                />
              )}

              {SubtaskDetails?.isTable &&
                SubtaskDetails?.calculationStatus === AppConstants.GCP_STATUS_IN_PROGRESS && (
                  <Chip
                    status={''}
                    label={
                      <Typography variant="Medium-14">
                        Calculation {capitalize(SubtaskDetails?.calculationStatus)}
                      </Typography>
                    }
                  />
                )}
              <Chip
                status={
                  SubtaskDetails?.status === 'CANCEL'
                    ? 'error'
                    : SubtaskDetails?.status === 'COMPLETED'
                      ? 'success'
                      : ''
                }
                label={
                  <Typography variant="Medium-14">
                    {capitalize(SubtaskDetails?.status || '')}
                  </Typography>
                }
              />
            </Box>
          }
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1.5rem',
            }}
          >
            <Typography variant="SemiBold-18">{SubtaskDetails?.name}</Typography>
            <Typography variant="Regular-14">{SubtaskDetails?.descrption}</Typography>
          </Box>

          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: '1.5rem',
              width: '100%',
            }}
          >
            <Typography variant="SemiBold-18" sx={{ flex: '1' }}>
              Tutorial Link
            </Typography>
            <Button
              label={<Typography variant="Medium-14">View</Typography>}
              onClick={() => window.open(SubtaskDetails?.tutorialLink)}
              sx={{ marginTop: '-5px' }}
              disabled={!SubtaskDetails?.tutorialLink}
            />
          </Box> */}

          <Box sx={{ width: '100%' }}>
            <Accordion
              summary={
                <Typography variant="Medium-14" color={theme.palette.text['100']}>
                  Details
                </Typography>
              }
              details={
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '40px',
                    rowGap: '16px',
                  }}
                >
                  {details.map(detail => (
                    <Box
                      key={detail.name}
                      sx={{
                        width: 'calc((100%/3) - 27px)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '24px',
                        backgroundColor: theme.palette.background,
                        borderRadius: '6px',
                        border: `1px solid ${theme.palette.text['500']}`,
                        padding: '12px',
                      }}
                    >
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.name}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          <Typography variant="Medium-14" color={theme.palette.text['300']}>
                            {detail.name}
                          </Typography>
                        </OverflowTooltip>
                      </Box>
                      <Box sx={{ width: 'calc(50% - 12px)' }}>
                        <OverflowTooltip
                          text={detail.description}
                          sx={{ color: theme.palette.text['100'] }}
                        >
                          {detail.description}
                        </OverflowTooltip>
                      </Box>
                    </Box>
                  ))}
                </Box>
              }
            />
          </Box>
          <ProgressDateComp
            remainingDays={SubtaskDetails?.remainingDays || 0}
            progress={SubtaskDetails?.progress || 0}
            startDate={SubtaskDetails?.startDate || '-'}
            endDate={SubtaskDetails?.endDate || '-'}
            statusModifiedDate={SubtaskDetails?.statusModifiedDate || '-'}
            userName={SubtaskDetails?.userName || ''}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1.5rem',
          }}
        >
          <Box sx={{ width: '210px' }}>
            <Select
              id="project-status"
              options={subTaskStatuses}
              placeholder="Select Project Status"
              optionMapFunction={options =>
                options?.map(x => ({ id: x, label: capitalize(x), value: x }))
              }
              value={selectedStatus}
              menuHeight="400px"
              selectHeight="40px"
              onChange={event => {
                setSelectedStatus(event.target.value);
              }}
              disabled={freezeAll}
            />
          </Box>
          <Box sx={{ width: '450px' }}>
            <Select
              id="project-team"
              options={[...projectTeams, SubtaskDetails?.assignTeamStr || '-']}
              placeholder=""
              optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
              value={SubtaskDetails?.assignTeamStr || '-'}
              startIcon={
                <Typography variant="Regular-14" color={theme.palette.text['100']}>
                  Assigned to:
                </Typography>
              }
              menuHeight="400px"
              selectHeight="40px"
              disabled={true}
            />
          </Box>
          <Button
            startIcon={<DependencyIcon fill={theme.palette.primary.main} />}
            label={
              <Typography variant="Medium-14">
                {SubtaskDetails?.dependency ? '1' : '0'} Dependency
              </Typography>
            }
            onClick={() => setOpenDependencyDialog(true)}
          />
          <Dialog
            open={openDependencyDialog}
            onClose={() => setOpenDependencyDialog(false)}
            width="668px"
            headline={
              <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
                View Dependency
              </Typography>
            }
            description={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: '24px',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '16px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    {SubtaskDetails?.dependency ? (
                      <>
                        <Box sx={{ width: 'calc(60% - 8px)', textAlign: 'left' }}>
                          <Input
                            label="Sub Task"
                            value={SubtaskDetails?.dependency || '-'}
                            disabled
                          />
                        </Box>
                        <Box sx={{ width: 'calc(40% - 8px)' }}>
                          <Input value="Start to Finish" disabled />
                        </Box>
                      </>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          paddingTop: '20px',
                        }}
                      >
                        <Typography variant="Medium-16">There is no dependency</Typography>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            }
            showDialogActions={false}
          />
        </Box>
        {!isLoading &&
          (isCheckBoxForm ? (
            <CheckboxSubmission
              data={SubtaskDetails?.projectSubmission || []}
              createUpdateSubtaskRequest={createUpdateSubtaskRequest}
              showReadyOnly={SubtaskDetails?.isView}
              url={SubtaskDetails?.url}
              freezeAll={freezeAll}
            />
          ) : (
            <MediaPlanSubmission
              fieldData={SubtaskDetails?.projectSubmission || []}
              createUpdateSubtaskRequest={createUpdateSubtaskRequest}
              showReadyOnly={SubtaskDetails?.isView}
              validationStatus={SubtaskDetails?.validationStatus}
              isValidate={SubtaskDetails?.isValidate}
              freezeAll={freezeAll}
              isTable={SubtaskDetails?.isTable}
              calculateTableSubtaskDetails={calculateTableSubtaskDetails}
              calculationStatus={SubtaskDetails?.calculationStatus}
            />
          ))}

        {isLoading &&
          (isCheckBoxForm ? (
            <CheckboxSubmission
              data={SubtaskDetails?.projectSubmission || []}
              createUpdateSubtaskRequest={createUpdateSubtaskRequest}
              showReadyOnly={SubtaskDetails?.isView}
              url={SubtaskDetails?.url}
              freezeAll={freezeAll}
            />
          ) : (
            <MediaPlanSubmission
              fieldData={SubtaskDetails?.projectSubmission || []}
              createUpdateSubtaskRequest={createUpdateSubtaskRequest}
              showReadyOnly={SubtaskDetails?.isView}
              validationStatus={SubtaskDetails?.validationStatus}
              isValidate={SubtaskDetails?.isValidate}
              freezeAll={freezeAll}
              isTable={SubtaskDetails?.isTable}
              calculateTableSubtaskDetails={calculateTableSubtaskDetails}
              calculationStatus={SubtaskDetails?.calculationStatus}
            />
          ))}

        <Comments entityId={params?.subtaskId} entityType="PROJECT_SUB_TASK" />
      </Card>
    </>
  );
};

export default SubtaskDetails;
