import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { Outlet } from 'react-router-dom';
import AppNavbar from '../components/layout/AppNavbar';
import AppSidebar from '../components/layout/AppSidebar';
import BackdropOverlay from '../components/shared/backdropOverlay';
import { dispatcher } from '../redux/store';
import { authActions } from '../redux/slice';
import { useAuth } from 'react-oidc-context';

const DashboardLayout = () => {
  const [open, setOpen] = React.useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const auth = useAuth();

  const handleShowLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const handleLogout = () => {
    auth.signoutRedirect();
    dispatcher(authActions.loggedOut());
    localStorage.removeItem('permissions');
    localStorage.removeItem('masterData');
  };

  useEffect(() => {
    window.setDeactivateBackdrop = val => {
      if (val) setBackdropOpen(true);
    };

    return () => {
      window.setDeactivateBackdrop = val => {};
    };
  }, []);

  return (
    <>
      {backdropOpen && (
        <BackdropOverlay
          open={backdropOpen}
          pageWarning={
            <>
              This account has been deactivated.
              <br /> Please contact your administrator for assistance.
            </>
          }
          pageHeadline="Account Deactivated"
          buttonLabel="Logout"
          clickAction={handleLogout}
          autharizationWarning={true}
        />
      )}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppNavbar
          open={open}
          showLogoutModal={showLogoutModal}
          handleShowLogoutModal={handleShowLogoutModal}
          handleCloseLogoutModal={handleCloseLogoutModal}
        />
        <AppSidebar
          open={open}
          setOpen={setOpen}
          showLogoutModal={showLogoutModal}
          handleShowLogoutModal={handleShowLogoutModal}
          handleCloseLogoutModal={handleCloseLogoutModal}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: '15px 25px',
            mt: 12.5,
            minHeight: 'calc(100vh - 100px)',
            width: 'calc(100vw - 104px)',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
