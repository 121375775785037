import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../../../components/shared/buttons';
import CheckboxMenu from '../../../../../components/shared/menu/CheckboxMenu';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddIcon, DownloadIcon, ErrorIcon } from '../../../../../libs/svg-icons/icons';
import SelectField from '../../../../../components/shared/form/Select';
import InputField from '../../../../../components/shared/form/Input';
import Dialog from '../../../../../components/shared/dialog';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import VersionHistoryTable from './VersionHistoryTable';
import { downloadMediaPlan } from '../../../../../services/media-plan-service';
import { getMimeType } from '../../../../../libs/document/file';
import { useHasAllPermission } from '../../../../../hooks/permissions';
import { PermissionsConstants } from '../../../../../constants/permissions-constants';
import { AppConstants } from '../../../../../constants/app-constants';
import { useEnqueueSnackbar } from '../../../../../components/shared/toast-provider/toastHook';

const saveAsNewVersionSchema = yup.object().shape({
  remarks: yup.string().required('Remark is required'),
});

const ViewMediaPlanTableUtils = ({
  handleSetMediaPlanVersion,
  handleSaveCurrentVersion,
  mediaPlanHeaderOptions,
  handleMenuItemClick,
  mediaPlanDetails,
  handleMediaPlanStatusChanged,
  handleSaveAsNewVersion,
  handleMediaPlanApprovalStatusChanged,
  isMediaPlanEditable,
}) => {
  const theme = useTheme();
  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);
  const [openSaveAsNewVersionDialog, setOpenSaveAsNewVersionDialog] = useState(false);
  const [openVersionHistoryDialog, setOpenVersionHistoryDialog] = useState(false);
  const [openedStatusChangeModal, setOpenedStatusChangeModal] = useState(null);

  const hasAllPermissions = useHasAllPermission();
  const enqueueSnackbar = useEnqueueSnackbar();

  const mediaPlanStatuses = useMemo(
    () => [
      {
        label: 'Draft',
        value: 'DRAFT',
        modalTitle: 'Change Status to Draft',
        modalDescription:
          'Are you sure you want to change the status of media plan to draft? You need to go again with the whole approval process.',
      },
      {
        label: 'Finalized',
        value: 'FINALIZED',
        modalTitle: 'Change Status to Finalized',
        modalDescription: 'Are you sure you want to change the status of media plan to finalized?',
      },
    ],
    [],
  );

  const saveAsNewVersionMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(saveAsNewVersionSchema),
    defaultValues: {
      remarks: '',
    },
  });

  const mediaPlanStatusMethods = useForm({
    mode: 'onTouched',
    defaultValues: {
      status:
        mediaPlanStatuses.find(x => x.value === mediaPlanDetails?.status)?.value ??
        mediaPlanStatuses[0].value,
    },
  });

  useEffect(() => {
    if (mediaPlanDetails?.status) {
      mediaPlanStatusMethods.setValue(
        'status',
        mediaPlanStatuses.find(x => x.value === mediaPlanDetails?.status)?.value,
      );
    }
  }, [mediaPlanDetails?.status]);

  const handleFilterChanged = useCallback(e => {
    const selectedValue = e.target.value;

    if (selectedValue === 'DRAFT' || selectedValue === 'FINALIZED') {
      setOpenedStatusChangeModal(selectedValue);
    } else {
      setOpenedStatusChangeModal(null);
    }
  }, []);

  const handleStatusChange = () => {
    setOpenedStatusChangeModal(prev => {
      const _status = mediaPlanStatuses.find(x => x.value === prev)?.value;
      handleMediaPlanStatusChanged(_status, () => {
        mediaPlanStatusMethods.setValue(
          'status',
          mediaPlanStatuses.find(x => x.value !== prev)?.value,
        );
      });
      // enqueueSnackbar(`Status changed Successfully`);

      return null;
    });
  };

  const handleOpenFieldsMenu = event => {
    setFieldAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  const handleCloseFieldsMenu = () => {
    setFieldAnchorEl(null);
  };

  const handleOpenSaveAsNewVersionDialog = () => {
    setOpenSaveAsNewVersionDialog(true);
  };

  const handleCloseSaveAsNewVersionDialog = () => {
    setOpenSaveAsNewVersionDialog(false);
  };

  const handleOpenVersionHistoryDialog = () => {
    setOpenVersionHistoryDialog(true);
  };

  const handleCLoseVersionHistoryDialog = () => {
    setOpenVersionHistoryDialog(false);
  };

  const handleCloseStatusChange = () => {
    setOpenedStatusChangeModal(prev => {
      // handleMediaPlanStatusChanged(mediaPlanStatuses.find(x => x.value !== prev)?.value);
      mediaPlanStatusMethods.setValue(
        'status',
        mediaPlanStatuses.find(x => x.value !== prev)?.value,
      );
      return null;
    });
  };

  const StatusSelect = useMemo(
    () => (
      <FormProvider {...mediaPlanStatusMethods}>
        <SelectField
          id="year-filter"
          options={mediaPlanStatuses}
          menuHeight="400px"
          selectHeight="40px"
          {...mediaPlanStatusMethods.register('status')}
          onChange={handleFilterChanged}
          disabled={
            !isMediaPlanEditable ||
            !hasAllPermissions([PermissionsConstants.UPDATE_MEDIA_PLAN]) ||
            ![AppConstants.MEDIA_PLAN_VALIDATION_VALIDATED].includes(
              mediaPlanDetails?.validationStatus,
            )
          }
        />
      </FormProvider>
    ),
    [
      mediaPlanStatusMethods,
      mediaPlanStatuses,
      handleFilterChanged,
      isMediaPlanEditable,
      hasAllPermissions,
      mediaPlanDetails?.validationStatus,
    ],
  );

  const downloadSheet = (result, fileName) => {
    const blob = new Blob([result?.data], {
      type: getMimeType('xlsm'),
      encoding: 'UTF-8',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const DownloadMediaPlanCall = async () => {
    await downloadMediaPlan(mediaPlanDetails.id)
      .then(response => {
        downloadSheet(response, mediaPlanDetails.name || 'media-plan-export-data');
      })
      .catch(error => {
        const { message = AppConstants.SOMETHING_WENT_WRONG } = error?.response?.data || {};
        enqueueSnackbar(message, 'error');
      });
  };

  const handleSaveAsNewVersionSubmit = data => {
    const remarkTemp = saveAsNewVersionMethods.getValues('remarks');
    if (remarkTemp.length) {
      setOpenSaveAsNewVersionDialog(false);
      handleSaveAsNewVersion({ remarks: remarkTemp });
    }
  };

  const handleMediaPlanVersionClicked = props => {
    setOpenVersionHistoryDialog(false);
    handleSetMediaPlanVersion(props);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (openSaveAsNewVersionDialog) saveAsNewVersionMethods.reset();
  }, [openSaveAsNewVersionDialog]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '16px',
        }}
      >
        <Button
          width="40px"
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '5px',
          }}
          variant="outlined"
          startIcon={
            <Box
              component={'span'}
              sx={{
                width: '21px',
                height: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DownloadIcon fill={theme.palette.primary.main} />
            </Box>
          }
          onClick={() => {
            DownloadMediaPlanCall();
          }}
        />
        <Box sx={{ width: '200px', height: '40px' }}>
          {StatusSelect}
          <Dialog
            open={!!openedStatusChangeModal}
            onClose={handleCloseStatusChange}
            icon={
              <Box
                sx={{
                  width: '52px',
                  height: '52px',
                  borderRadius: '50%',
                  background: theme.palette.background,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                  marginBottom: '24px',
                }}
              >
                <ErrorIcon fill="var(--deep-navy)" width="32px" height="32px" />
              </Box>
            }
            headline={
              <Typography variant="SemiBold-22">
                {mediaPlanStatuses.find(x => x.value === openedStatusChangeModal)?.modalTitle}
              </Typography>
            }
            description={
              <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
                {mediaPlanStatuses.find(x => x.value === openedStatusChangeModal)?.modalDescription}
              </Typography>
            }
            buttons={[
              <Button
                type="button"
                key="button1"
                width="176px"
                variant="outlined"
                label="Cancel"
                onClick={handleCloseStatusChange}
              />,
              <Button
                type="submit"
                key="button2"
                width="176px"
                variant="contained"
                label="Yes, Change"
                onClick={handleStatusChange}
              />,
            ]}
            width="420px"
            headlineAlignment="center"
          />
        </Box>
        <Button
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '8px 20px',
          }}
          variant="outlined"
          onClick={handleOpenSaveAsNewVersionDialog}
          disabled={
            !isMediaPlanEditable ||
            !hasAllPermissions([PermissionsConstants.CREATE_MEDIA_PLAN_VERSION])
          }
          label="Save as New Version"
        />
        {hasAllPermissions([PermissionsConstants.CREATE_MEDIA_PLAN_VERSION]) && (
          <FormProvider {...saveAsNewVersionMethods}>
            <Dialog
              open={openSaveAsNewVersionDialog}
              onClose={handleCloseSaveAsNewVersionDialog}
              headline={
                <Typography
                  variant="SemiBold-22"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  Add Remark for this Version
                </Typography>
              }
              description={
                <Box sx={{ width: '100%' }}>
                  <form onKeyDown={handleKeyDown}>
                    <InputField
                      id="remarks"
                      type="text"
                      label="Remark"
                      placeholder="Enter the remark for this version"
                      {...saveAsNewVersionMethods.register('remarks')}
                    />
                  </form>
                </Box>
              }
              buttons={[
                <Button
                  type="submit"
                  onClick={handleSaveAsNewVersionSubmit}
                  disabled={!saveAsNewVersionMethods.formState.isValid}
                  width="167px"
                  variant="contained"
                  label="Save Version"
                />,
              ]}
              width="620px"
              height="120px"
              backgroundColor="white"
            />
          </FormProvider>
        )}
        <Button
          height="40px"
          onClick={handleSaveCurrentVersion}
          sx={{
            minWidth: '21px',
            padding: '8px 20px',
          }}
          disabled={!isMediaPlanEditable || !mediaPlanDetails?.isEditing}
          variant="outlined"
          label={`Save Current Version - ${mediaPlanDetails?.activeVersionNumber ?? 1}`}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '16px',
        }}
      >
        <Button
          height="40px"
          sx={{
            minWidth: '21px',
            padding: '8px 20px',
          }}
          onClick={handleOpenVersionHistoryDialog}
          disabled={!hasAllPermissions([PermissionsConstants.READ_MEDIA_PLAN_VERSION])}
          variant="outlined"
          label="View Version History"
        />

        {hasAllPermissions([PermissionsConstants.READ_MEDIA_PLAN_VERSION]) && (
          <Dialog
            open={openVersionHistoryDialog}
            onClose={handleCLoseVersionHistoryDialog}
            headline={
              <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
                Version History
              </Typography>
            }
            table={
              <VersionHistoryTable
                handleSetMediaPlanVersion={handleMediaPlanVersionClicked}
                mediaPlanDetails={mediaPlanDetails}
              />
            }
            showDialogActions={false}
            width="1300px"
          />
        )}
        <Button
          aria-controls={fieldAnchorEl ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={fieldAnchorEl ? 'true' : undefined}
          variant="outlined"
          label="Field"
          onClick={handleOpenFieldsMenu}
          startIcon={<AddIcon fill={theme.palette.primary.main} />}
        />
        <CheckboxMenu
          anchorEl={fieldAnchorEl}
          values={mediaPlanHeaderOptions}
          onMenuItemClick={handleMenuItemClick}
          onClose={handleCloseFieldsMenu}
          checkboxProps={{ checked: true }}
          searchPlaceholder="Select Column"
        />
      </Box>
    </Box>
  );
};

export default ViewMediaPlanTableUtils;
