import React, { useEffect, useRef, useState } from 'react';
import Dialog from '../../../../../../components/shared/dialog';
import { Box, Typography } from '@mui/material';
import Button from '../../../../../../components/shared/buttons';

import { useTheme } from '@mui/material/styles';
import DragAndDropFilePicker from '../../../../../../components/shared/form/fileupload';
import { UploadIcon } from '../../../../../../libs/svg-icons/icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import DatePicker from '../../../../../../components/shared/form/date-picker';
import { formatDate } from '../../../../../../libs/date/format';
import dayjs from 'dayjs';

const schema = yup.object().shape({
  IBDocument: yup.mixed().required('IB document is required'),
});

export default function UploadSignIBPopup({
  open = false,
  onClose = () => {},
  handleUploadSignedIB = () => {},
  createdAtIB = '',
  ibCreationDate = '',
}) {
  const theme = useTheme();

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      IBDocument: null,
    },
  });

  const watchValues = methods.watch();

  const handleSubmitSignIB = () => {
    handleUploadSignedIB(watchValues.IBDocument, methods.watch('date') || null);
    onClose();
  };

  const handleMediaPlanTemplateUpload = file => {};

  const handleMediaPlanTemplateRemoved = () => {
    methods.setValue('IBDocument', undefined, {
      shouldValidate: true,
    });
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        fullBodyScroll={true}
        open={open}
        onClose={onClose}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            Upload Signed IB from Client
          </Typography>
        }
        description={
          <Box sx={{ width: '100%', textAlign: 'left', height: '420px' }}>
            <Typography variant="Medium-16" alignItems="flex-start" justifyContent="flex-start">
              Upload IB
            </Typography>
            <Box sx={{ width: '100%', marginTop: '5px' }}>
              <DragAndDropFilePicker
                multi={false}
                icon={<UploadIcon fill={theme.palette.primary.main} />}
                headline="Upload IB Document"
                description="(Only .pdf file, maximum 5MB file size)"
                onUpload={handleMediaPlanTemplateUpload}
                onRemove={handleMediaPlanTemplateRemoved}
                acceptedfileExtensions={['pdf']}
                draggable={false}
                maxFileSize={5}
                {...methods.register('IBDocument')}
              />
            </Box>

            <Box sx={{ width: '100%', marginTop: '25px' }}>
              <Typography
                variant="Medium-16"
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ color: '#6D7581' }}
              >
                IB Created Date: {formatDate(ibCreationDate || createdAtIB, 'MMMM dd, yyyy')}
              </Typography>
            </Box>
            <Box sx={{ width: '300px', marginTop: '25px' }}>
              <DatePicker
                label="IB Approved Date"
                id="date"
                placeholder="Select date"
                textAlign="left"
                // minDate={dayjs(createdAtIB)}
                // maxDate={dayjs(new Date())}
                {...methods.register('date')}
                onChange={() => null}
              />
            </Box>
          </Box>
        }
        buttons={
          <Button
            key="button-1"
            type="submit"
            variant="contained"
            label="Upload IB"
            onClick={handleSubmitSignIB}
            disabled={!methods.formState.isValid || !methods.watch('date')}
            height="40px"
          />
        }
        width="668px"
        backgroundColor="white"
      />
    </FormProvider>
  );
}
