import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../../../components/shared/header';
import Card from '../../../../components/shared/card';
import { Box, Typography } from '@mui/material';
import Chip from '../../../../components/shared/chip';
import OverflowTooltip from '../../../../components/shared/tooltip/OverflowTooltip';
import { useTheme } from '@mui/material/styles';
import Table from '../../../../components/shared/table';
import Accordion from '../../../../components/shared/accordion';
import Progress from '../../../../components/shared/progress';
import Select from '../../../../components/shared/form/Select/select';
import Button from '../../../../components/shared/buttons';
import { childColumns, childData, columns, data } from './ColumnData';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getProjectDetails,
  getProjectStatusList,
  updateProjectStatus,
  updateProjectTaskRemark,
  projectApprovalRequest,
} from '../../../../services/project-service';
import { useEnqueueSnackbar } from '../../../../components/shared/toast-provider/toastHook';
import { AppConstants } from '../../../../constants/app-constants';
import * as mockData from '../ProjectsList/filterOptionSample.json';
import { capitalize } from '../../../../utils/string';
import { sortBy } from 'lodash';
import { formatDate } from '../../../../libs/date/format';
import { getFirstCharOfEveryWord, getReaminingDaysPercentage } from '../../../../utils/project';
import ProgressDateComp from './ProgressDateComp';
import Loader from '../../../../components/shared/loader';
import ApproveRejectAction from '../../../../components/shared/approve-reject';
import { useAppSelector } from '../../../../redux/hooks';
import { handleError } from '../../../../utils/errorHandling';
import BackdropOverlay from '../../../../components/shared/backdropOverlay';

const { projectStatus, projectDetails } = mockData;

const ProjectDetails = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const enqueueSnackbar = useEnqueueSnackbar();

  const userInfo = useAppSelector(state => state.auth);

  const [isLoading, setIsloading] = useState(true);
  const [expandAll, setExpandAll] = useState(false);
  const [details, setDetails] = useState([
    { name: 'Responsible Team', description: 'Buying Team' },
    { name: 'Consulting Team', description: 'Buying Team' },
    { name: 'Timeline', description: 'One Month' },
    { name: 'Accountable Team', description: 'Buying Team' },
    { name: 'Informed Team', description: 'Buying Team' },
  ]);
  const [projectDetailsData, setProjectDetailsData] = useState(null);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [showApproveTool, setApproveTool] = useState(false);

  const [pageAccess, setPageAccess] = useState(false);

  const loggedInUserTeams = useMemo(() => {
    return userInfo?.teams?.map(x => x.id);
  }, [userInfo]);

  const projectTeams = ['Buying Team', 'Finance Team', 'Consulting Team', 'Reviewing Team'];

  useEffect(() => {
    fetchProjectStatusList();
  }, []);

  useEffect(() => {
    if (projectStatuses?.length) {
      fetchProjectDetails();
    }
  }, [projectStatuses]);

  const fetchProjectDetails = async () => {
    setIsloading(true);
    await getProjectDetails(params?.id)
      .then(res => {
        const { data } = res.data;
        formatProjectData(data);
      })
      .catch(error => {
        const { status = '' } = error?.response?.data || {};
        if (status === AppConstants.HTTP_FORBIDDEN) {
          setPageAccess(true);
        }
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const fetchProjectStatusList = async () => {
    setIsloading(true);
    await getProjectStatusList('PROJECT')
      .then(res => {
        const { data } = res.data;
        const t = data.map(x => x);
        setProjectStatuses(t);
      })
      .catch(error => {
        let message = handleError(error);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const updateProjectTaskRemarkCall = useCallback(
    async (params, payload, callBack = () => null) => {
      setIsloading(true);
      await updateProjectTaskRemark(params, payload)
        .then(res => {
          callBack();
          fetchProjectDetails();
        })
        .catch(error => {
          let message = handleError(error);
          enqueueSnackbar(message, 'error');
        })
        .finally(() => {
          setIsloading(false);
        });
    },
    [],
  );

  const updateProjectStatusCall = async status => {
    setIsloading(true);
    const payload = {
      'entityType': AppConstants.ENTITY_TYPE_PROJECT,
      'projectId': params?.id,
      'status': status,
    };

    await updateProjectStatus(payload)
      .then(res => {
        fetchProjectDetails();
      })
      .catch(error => {
        let message = handleError(error);
        setSelectedStatus(projectDetailsData?.status);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const approveRejectProject = async status => {
    setIsloading(true);
    const payload = {
      'approvalId': projectDetailsData?.projectApproval?.id,
      'projectId': params?.id,
      'status': status,
    };

    await projectApprovalRequest(payload)
      .then(res => {
        enqueueSnackbar(`Request ${capitalize(status)} sucessfully.`, 'success');
        fetchProjectDetails();
      })
      .catch(error => {
        let message = handleError(error);
        setSelectedStatus(projectDetailsData?.status);
        enqueueSnackbar(message, 'error');
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    if (projectDetailsData?.status && projectDetailsData?.status !== selectedStatus) {
      updateProjectStatusCall(selectedStatus);
    }
  }, [selectedStatus]);

  const showApprovalToolbar = (allowedTeams, requestStatus) => {
    let flag = false;
    if (requestStatus === 'IN_PROGRESS') {
      allowedTeams.forEach(team => {
        if (loggedInUserTeams.includes(team)) {
          flag = true;
        }
      });
    }
    return flag;
  };

  const formatProjectData = response => {
    const {
      accountableTeam = '-',
      consultingTeam = '-',
      responsibleTeam = '-',
      informedTeam = [],
      assignedTeam = [],
      duration,
      status = '',
      startDate = '',
      endDate = '',
      statusModifiedDate = '',
      taskModel = [],
      projectApproval,
    } = response;
    setDetails([
      {
        name: 'Responsible Team',
        description: responsibleTeam?.length ? responsibleTeam.join(', ') : '-',
      },
      {
        name: 'Consulting Team',
        description: consultingTeam?.length ? consultingTeam.join(', ') : '-',
      },
      { name: 'Timeline', description: `${duration} Days` || '-' },
      {
        name: 'Accountable Team',
        description: accountableTeam?.length ? accountableTeam.join(', ') : '-',
      },
      { name: 'Informed Team', description: informedTeam?.length ? informedTeam.join(', ') : '-' },
    ]);

    setSelectedStatus(status);

    const { parentData, childData } = formateTaskList(sortBy(taskModel, 'id'));
    const { progress, remainingDays } = getReaminingDaysPercentage(startDate, endDate);

    if (projectApproval?.requestStatus)
      setApproveTool(
        status === 'COMPLETED'
          ? showApprovalToolbar(projectApproval?.approverTeamIds, projectApproval?.requestStatus)
          : false,
      );

    setProjectDetailsData({
      ...response,
      startDate: startDate ? formatDate(startDate, 'do MMMM, yyyy') : '-',
      endDate: endDate ? formatDate(endDate, 'do MMMM, yyyy') : '-',
      statusModifiedDate: statusModifiedDate
        ? formatDate(statusModifiedDate, `do MMMM, yyyy 'at' hh:mm a`)
        : '-',
      taskModels: sortBy(parentData, 'id'),
      subTaskModels: sortBy(childData, 'id'),
      progress,
      remainingDays,
      assignedTeam: assignedTeam?.join(', '),
    });
  };

  const formateTaskList = data => {
    let parentData = [];
    let childData = [];

    data.forEach(ele => {
      const {
        id: taskId = '-',
        startDate,
        endDate,
        status = '-',
        task: { externalId: parentId = '-', name = '-' },
        remarks = '',
        subTask = [],
        assignedTeam = ' ',
        requestStatus = '',
      } = ele || {};
      parentData.push({
        remarkId: taskId,
        id: parentId,
        name: name,
        startDate: startDate ? formatDate(startDate, 'do MMM, yyyy') : '-',
        endDate: endDate ? formatDate(endDate, 'do MMM, yyyy') : '-',
        status: status,
        assignedTeam: assignedTeam, // ? getFirstCharOfEveryWord(assignedTeam) : '-',
        remark: remarks,
        requestStatus,
      });

      sortBy(subTask, 'id')?.forEach(sub => {
        const {
          id = '-',
          startDate,
          endDate,
          status = '-',
          task: { externalId = '-', name = '-' },
          remarks = '',
          assignedTeam = ' ',
          requestStatus = '',
        } = sub || {};

        childData.push({
          parentId: parentId,
          taskId: taskId,
          remarkId: id,
          id: externalId,
          name: name,
          startDate: startDate ? formatDate(startDate, 'do MMM, yyyy') : '-',
          endDate: endDate ? formatDate(endDate, 'do MMM, yyyy') : '-',
          status: status,
          assignedTeam: assignedTeam, //? getFirstCharOfEveryWord(assignedTeam) : '-',
          remark: remarks,
          requestStatus,
        });
      });
    });

    return { parentData, childData };
  };

  const handleViewTaskDetails = useCallback(
    data => {
      if (data?.id) {
        navigate(`/app/projects/${params.id}/view-project/${encodeURIComponent(data?.remarkId)}`, {
          replace: false,
        });
      }
    },
    [navigate, params.id],
  );

  const handleViewSubTaskTaskDetails = useCallback(
    data => {
      console.log(data);
      if (data?.id) {
        navigate(
          `/app/projects/${params.id}/view-project/${encodeURIComponent(data?.taskId)}/${encodeURIComponent(data?.remarkId)}`,
          {
            replace: false,
          },
        );
      }
    },
    [navigate, params.id],
  );

  const columnDefs = useMemo(
    () => columns(handleViewTaskDetails, updateProjectTaskRemarkCall, projectDetailsData?.status),
    [handleViewTaskDetails, updateProjectTaskRemarkCall, projectDetailsData],
  );
  const childColumnDefs = useMemo(
    () =>
      childColumns(
        handleViewSubTaskTaskDetails,
        updateProjectTaskRemarkCall,
        projectDetailsData?.status,
      ),
    [handleViewSubTaskTaskDetails, updateProjectTaskRemarkCall, projectDetailsData],
  );

  const ProjectDetailsTable = useMemo(
    () => (
      <Table
        variant="mui-table"
        data={projectDetailsData?.taskModels || []}
        columns={columnDefs}
        childData={projectDetailsData?.subTaskModels || []}
        childColumns={childColumnDefs}
        hasChild
        expandAll={expandAll}
        showChildHeader={false}
        childStyles={{ paddingLeft: '60px', backgroundColor: '#f7f7f7' }}
      />
    ),
    [childColumnDefs, columnDefs, expandAll, projectDetailsData],
  );

  const headerComp = useMemo(
    () => (
      <Header
        breadcrumbs={[{ name: 'View Project' }]}
        rightActions={
          <Box>
            {projectDetailsData?.projectApproval?.requestStatus ===
              AppConstants.GCP_STATUS_IN_PROGRESS && (
              <Chip
                status={''}
                label={<Typography variant="Medium-14">Approval Pending</Typography>}
                styles={{ marginRight: '15px' }}
              />
            )}
            {projectDetailsData && (
              <Chip
                status={
                  selectedStatus === 'COMPLETED'
                    ? 'success'
                    : selectedStatus === 'CANCELLED'
                      ? 'error'
                      : ''
                }
                label={
                  <Typography variant="Medium-14">{capitalize(selectedStatus) || '-'}</Typography>
                }
              />
            )}
          </Box>
        }
      />
    ),
    [selectedStatus, projectDetailsData],
  );

  const freezeAll = useMemo(() => {
    return projectDetailsData?.status === AppConstants.PROJECT_STATUS_CANCEL;
  }, [projectDetailsData]);

  return (
    <>
      <BackdropOverlay
        open={pageAccess}
        pageWarning={AppConstants.PAGE_ACCESS_ERROR}
        buttonLabel="Redirect To Projects"
        clickAction={() => {
          navigate(`/app/projects`);
        }}
        autharizationWarning={true}
      />

      <Loader isLoading={isLoading} />

      <Card sx={{ alignItems: 'flex-start' }}>
        {projectDetailsData && showApproveTool && !freezeAll && (
          <ApproveRejectAction
            onApprove={() => {
              approveRejectProject(AppConstants.EDIT_REQUEST_APPROVED);
            }}
            onReject={() => {
              approveRejectProject(AppConstants.EDIT_REQUEST_REJECTED);
            }}
          />
        )}

        {headerComp}
        {projectDetailsData ? (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '1.5rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  gap: '1.5rem',
                  width: '100%',
                }}
              >
                <OverflowTooltip text={projectDetailsData?.name || '-'}>
                  <Typography variant="SemiBold-18">{projectDetailsData?.name || '-'}</Typography>
                </OverflowTooltip>
                <Typography variant="Regular-14">
                  {projectDetailsData?.description || '-'}
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Accordion
                  summary={
                    <Typography variant="Medium-14" color={theme.palette.text['100']}>
                      Details
                    </Typography>
                  }
                  details={
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '40px',
                        rowGap: '16px',
                      }}
                    >
                      {details.map(detail => (
                        <Box
                          key={detail.name}
                          sx={{
                            width: 'calc((100%/3) - 27px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '24px',
                            backgroundColor: theme.palette.background,
                            borderRadius: '6px',
                            border: `1px solid ${theme.palette.text['500']}`,
                            padding: '12px',
                          }}
                        >
                          <Box sx={{ width: 'calc(50% - 12px)' }}>
                            <OverflowTooltip
                              text={detail.name}
                              sx={{ color: theme.palette.text['100'] }}
                            >
                              <Typography variant="Medium-14" color={theme.palette.text['300']}>
                                {detail.name}
                              </Typography>
                            </OverflowTooltip>
                          </Box>
                          <Box sx={{ width: 'calc(50% - 12px)' }}>
                            <OverflowTooltip
                              text={detail.description}
                              sx={{ color: theme.palette.text['100'] }}
                            >
                              {detail.description}
                            </OverflowTooltip>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  }
                />
              </Box>
              <ProgressDateComp
                remainingDays={projectDetailsData?.remainingDays}
                progress={projectDetailsData?.progress}
                startDate={projectDetailsData?.startDate}
                endDate={projectDetailsData?.endDate}
                statusModifiedDate={projectDetailsData?.statusModifiedDate}
                userName={projectDetailsData?.userName || ''}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1.5rem',
              }}
            >
              <Box sx={{ width: '210px' }}>
                <Select
                  id="project-status"
                  options={projectStatuses}
                  placeholder="Select Project Status"
                  optionMapFunction={options =>
                    options?.map(x => ({ id: x, label: capitalize(x), value: x }))
                  }
                  value={selectedStatus}
                  menuHeight="400px"
                  selectHeight="40px"
                  onChange={e => {
                    setSelectedStatus(e.target.value);
                  }}
                  disabled={freezeAll}
                />
              </Box>
              <Box sx={{ width: '450px' }}>
                <Select
                  id="project-team"
                  options={[...projectTeams, projectDetailsData?.assignedTeam || '']}
                  placeholder=""
                  optionMapFunction={options => options?.map(x => ({ id: x, label: x, value: x }))}
                  value={projectDetailsData?.assignedTeam || ''}
                  startIcon={
                    <Typography
                      variant="Regular-14"
                      color={theme.palette.text['100']}
                      sx={{ opacity: '0.5', marginLeft: 'auto' }}
                    >
                      Assigned to:
                    </Typography>
                  }
                  menuHeight="400px"
                  selectHeight="40px"
                  disabled={true}
                />
              </Box>
              <Button
                label={expandAll ? 'Collapse All' : 'Expand All'}
                onClick={() => setExpandAll(prev => !prev)}
              />
            </Box>
            {ProjectDetailsTable}
          </>
        ) : (
          !isLoading && (
            <div
              style={{ width: '100%', height: '550px', textAlign: 'center', paddingTop: '200px' }}
            >
              <Typography variant="Medium-16" style={{ fontSize: '20px' }}>
                Project Data Not Found
              </Typography>
            </div>
          )
        )}
      </Card>
    </>
  );
};

export default ProjectDetails;
