import { Backdrop, Box, Typography } from '@mui/material';
import React from 'react';
import Button from '../buttons';
import './backdrop.css';
import PermissionIcon from '../../../assets/images/permissions.svg';
import Dialog from '../dialog';
import { useTheme } from '@emotion/react';

export default function BackdropOverlay({
  open,
  pageWarning = 'You are not authorized to view this page',
  pageHeadline = 'Warning',
  handleClose = () => {},
  buttonLabel = 'Redirect',
  clickAction = () => {},
  autharizationWarning = false,
}) {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <Dialog
        open={open}
        onClose={() => {}}
        icon={
          <Box
            sx={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              background: theme.palette.background,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          >
            <img
              alt="permissions"
              src={PermissionIcon}
              style={{ width: '50px', height: '50px', marginBottom: '10px' }}
            />
          </Box>
        }
        headline={<Typography variant="SemiBold-22">{pageHeadline}</Typography>}
        description={
          <Typography variant="Regular-16" sx={{ marginBottom: '24px' }}>
            {pageWarning}
          </Typography>
        }
        buttons={[
          <Button
            key="button2"
            width="250px"
            variant="contained"
            label={buttonLabel}
            onClick={clickAction}
          />,
        ]}
        width={'500px'}
        headlineAlignment="center"
        autharizationWarning={autharizationWarning}
      />
      {/* <div
        style={{
          textAlign: 'center',
          minWidth: '450px',
          padding: '40px 100px',
          background: '#fff',
          borderRadius: '5px',
        }}
      >
        <div>
          <img
            alt="permissions"
            src={PermissionIcon}
            style={{ width: '50px', height: '50px', marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '20px', color: '#000' }}>
          <Typography variant="Medium-16">{pageWarning}</Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Button
            type="button"
            key="redirect-btn"
            variant="contained"
            label={buttonLabel}
            onClick={clickAction}
          />
        </div>
      </div> */}
    </Backdrop>
  );
}
