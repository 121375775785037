import FaviconIcon from '../../src/assets/images/Favicon.png';

export const throwNotification = (payload = {}) => {
  const notificationTitle = payload?.notification?.title || 'No Title';
  const notificationOptions = {
    body: payload?.notification?.body || 'No Body',
    icon: FaviconIcon,
    data: {
      url: 'https://yourwebsite.com/specific-page', // Target URL
    },
  };

  if (Notification.permission === 'granted') {
    new Notification(notificationTitle, notificationOptions);
  } else {
    console.error('Notification permission not granted.');
  }
};
