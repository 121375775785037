import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { dispatcher, store } from '../../../../redux/store';
import { authActions } from '../../../../redux/slice';
import { verifyUser } from '../../../../services/authentication-service';
import CircularProgress from '@mui/material/CircularProgress';
import BackdropOverlay from '../../../../components/shared/backdropOverlay';
// import { requestPermission } from '../../../../firebase/firebase';
// import { saveFCMToken } from '../../../../services/media-plan-service';

const Callback = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [getuser, setGetUser] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleLogout = () => {
    auth.signoutRedirect();
    dispatcher(authActions.loggedOut());
    localStorage.removeItem('permissions');
    localStorage.removeItem('masterData');
  };

  useEffect(() => {
    window.setDeactivateBackdropCallback = val => {
      if (val) setBackdropOpen(true);
    };

    return () => {
      window.setDeactivateBackdropCallback = val => {};
    };
  }, []);

  useEffect(() => {
    auth.signinSilent();
  }, []);

  useEffect(() => {
    if (auth.user && auth.isAuthenticated && auth.user.access_token && !getuser) {
      verifyUserAfterLogin();
      // onSuccessUserVerified();
    }
  }, [auth.user, auth.isAuthenticated]);

  const getPermissions = data => {
    let permissions = [];
    let roles = [];
    data.forEach(ele => {
      roles.push(ele.name);
      permissions = [...permissions, ...ele.permissions];
    });

    return { roles, permissions };
  };

  const verifyUserAfterLogin = async () => {
    await verifyUser()
      .then(response => {
        const { data = {} } = response?.data || {};
        const { userRoles = [], id = '' } = data;
        const [firstItem = null] = userRoles;
        let rolePermission = getPermissions(userRoles);

        onSuccessUserVerified(
          rolePermission.roles,
          rolePermission.permissions,
          id,
          firstItem?.businessUnit || '',
          firstItem?.teams || [],
        );
      })
      .catch(error => {
        const { response = null } = error || {};
        if (!response) {
          console.log(error, auth.user);
        } else {
          const { data = {} } = response || {};
          const { body = {}, status = '200', detail = 'error' } = data || {};
          // const {  detail = 'error' } = body || {};

          if (status === '417') {
            setBackdropOpen(true);
          }

          if (status === 404) {
            alert(detail);
            auth.signoutRedirect();
          }
        }
      });
  };

  const onSuccessUserVerified = async (
    userRoles = [],
    permissions = [],
    id = '',
    bu,
    teams = [],
  ) => {
    localStorage.setItem('userRoles', JSON.stringify(userRoles));
    localStorage.setItem('permissions', JSON.stringify(permissions));
    localStorage.setItem('userTeams', JSON.stringify(teams));

    try {
      // const token = await requestPermission();
      // localStorage.setItem('fcmToken', token);
      // console.log('token generated sucessfully');
      dispatcher(
        authActions.loggedIn({
          user: auth.user.profile,
          loggedInUserId: id,
          userRoles,
          permissions,
          fcmToken: '',
          businessUnit: bu,
          teams: teams,
        }),
      );

      // await sendFCMToken(token);
    } catch (error) {
      console.error('Error handling FCM token: ', error);
    }
  };

  // const sendFCMToken = async token => {
  //   // console.log('Calling saveFCMToken API with token:', token);
  //   try {
  //     const response = await saveFCMToken(token);
  //     if (response.status === 200) {
  //       // console.log('FCM token successfully sent to server.');
  //     } else {
  //       console.error('Failed to send FCM token to server.', response);
  //     }
  //   } catch (error) {
  //     console.error('Error calling FCM token API:', error);
  //   }
  // };

  return (
    <>
      {backdropOpen && (
        <BackdropOverlay
          open={backdropOpen}
          pageWarning={
            <>
              This account has been deactivated.
              <br /> Please contact your administrator for assistance.
            </>
          }
          pageHeadline="Account Deactivated"
          buttonLabel="Logout"
          clickAction={handleLogout}
          autharizationWarning={true}
        />
      )}
      <div style={{ width: '100%', textAlign: 'center', paddingTop: '100px' }}>
        <CircularProgress style={{ margin: 'auto' }} />
        {/* <button onClick={verifyUserAfterLogin}>Verify User</button> */}
      </div>
    </>
  );
};

export default Callback;
