import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dialog from '../../../../../../components/shared/dialog';
import { Box, Typography } from '@mui/material';
import Button from '../../../../../../components/shared/buttons';
import InputField from '../../../../../../components/shared/form/Input';
import quillPenIcon from '../../../../../../libs/svg-icons/icons/quill-pen.svg';
import ballPenIcon from '../../../../../../libs/svg-icons/icons/ball-pen.svg';
import ReactSignatureCanvas from 'react-signature-canvas';
import domtoimage from 'dom-to-image';
import Loader from '../../../../../../components/shared/loader';
import { AppConstants } from '../../../../../../constants/app-constants';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEnqueueSnackbar } from '../../../../../../components/shared/toast-provider/toastHook';
import DatePicker from '../../../../../../components/shared/form/date-picker';
import { formatDate } from '../../../../../../libs/date/format';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

const schema = yup.object().shape({
  signatureType: yup.string(),
  // signatureText: yup
  //   .string()
  //   .test({
  //     name: 'is-required',
  //     message: 'Sign is required.',
  //     test: (val, ctx) => (ctx.parent.signatureType === 'text' ? !!val : true),
  //   })
  //   .max(50, 'Sign must not exceed 50 characters')
  //   .matches(/^\S*$/, 'No spaces are allowed in the signature text'),
  // signatureDrawing: yup.mixed().test({
  //   name: 'is-required',
  //   message: 'Sign is required.',
  //   test: (val, ctx) => (ctx.parent.signatureType === 'draw' ? !!val : true),
  // }),
  progTypeConsideration: yup.string().max(200, 'Keyword must not exceed 200 characters'),
  remark: yup.string().max(200, 'Keyword must not exceed 200 characters'),
  secondaryBrand: yup.string().max(50, 'Keyword must not exceed 50 characters'),
  campaignType: yup.string().max(50, 'Keyword must not exceed 50 characters'),
  campaignOutline: yup.string().max(50, 'Keyword must not exceed 50 characters'),
  // date: yup.object().required(' Date is required'),
});

export default function SignIBPopup({
  open = false,
  onClose = () => {},
  handleIBSignData = () => {},
}) {
  const signatureRef = useRef();
  const theme = useTheme();
  const enqueueSnackbar = useEnqueueSnackbar();

  const user = useAppSelector(state => state.auth.user);

  const userName = useMemo(() => {
    return user?.name || '-';
  }, [user]);

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      signatureType: 'text',
      signatureText: '',
      progTypeConsideration: '',
      remark: '',
      secondaryBrand: '',
      campaignType: '',
      campaignOutline: '',
      //date: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    signatureType: 'text',
    signatureText: '',
    signatureDrawing: '',
    progTypeConsideration: '',
    remark: '',
    secondaryBrand: '',
    campaignType: '',
    campaignOutline: '',
    date: '',
  });

  useEffect(() => {
    if (!open) {
      // methods.reset();
    }
  }, [methods, open]);

  useEffect(() => {
    init();
  }, [open, methods]);

  const init = () => {
    const {
      signatureType = 'text',
      signatureText = '',
      signatureDrawing = '',
      progTypeConsideration = '',
      remark = '',
      secondaryBrand = '',
      campaignType = '',
      campaignOutline = '',
      date = '',
    } = state;

    methods.setValue('signatureDrawing', methods.watch('signatureDrawing') || signatureDrawing, {
      shouldValidate: true,
    });
    // methods.setValue('signatureType', methods.watch('signatureType') || signatureType, {
    //   shouldValidate: true,
    // });
    if (signatureText)
      methods.setValue('signatureText', methods.watch('signatureText') || signatureText, {
        shouldValidate: true,
      });
    methods.setValue(
      'progTypeConsideration',
      methods.watch('progTypeConsideration') || progTypeConsideration,
      {
        shouldValidate: true,
      },
    );
    methods.setValue('remark', methods.watch('remark') || remark, {
      shouldValidate: true,
    });
    methods.setValue('secondaryBrand', methods.watch('secondaryBrand') || secondaryBrand, {
      shouldValidate: true,
    });
    methods.setValue('campaignType', methods.watch('campaignType') || campaignType, {
      shouldValidate: true,
    });
    methods.setValue('campaignOutline', methods.watch('campaignOutline') || campaignOutline, {
      shouldValidate: true,
    });
    methods.setValue('date', methods.watch('date') || date, {
      shouldValidate: true,
    });
  };

  const convertDateFormat = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // This is old function keep in case if we need ot revert the code
  const setImageToElementOLD = (baseImg, ele = null) => {
    const domElement = document.getElementById('signature-1');
    // const domElement2 = document.getElementById('signature-2');
    const domProgramType = document.getElementById('program-type');
    const domRemark = document.getElementById('remark');
    const domSecondaryBrand = document.getElementById('secondary-brand');
    const domCampaignType = document.getElementById('campaign-type');
    const domCampaignOutline = document.getElementById('campaign-outline');
    const domDate = document.getElementById('date');

    // domElement.style.backgroundImage = `url(${baseImg})`;
    // domElement.style.backgroundSize = '100% 95%';

    if (ele) domElement.appendChild(ele);

    domProgramType.innerHTML = methods.watch('progTypeConsideration') ?? '';
    domRemark.innerHTML = methods.watch('remark') ?? '';
    domSecondaryBrand.innerHTML = methods.watch('secondaryBrand') ?? '';
    domCampaignType.innerHTML = methods.watch('campaignType') ?? '';
    domCampaignOutline.innerHTML = methods.watch('campaignOutline') ?? '';
    if (methods.watch('date')) domDate.innerHTML = convertDateFormat(methods.watch('date'));
  };

  const setImageToElement = (baseImg, ele = null) => {
    const domElement = document.getElementById('digital-signature');
    // const domElement = document.getElementById('signature-1');
    const domProgramType = document.getElementById('program-type');
    const domRemark = document.getElementById('remark');
    const domSecondaryBrand = document.getElementById('secondary-brand');
    const domCampaignType = document.getElementById('campaign-type');
    const domCampaignOutline = document.getElementById('campaign-outline');
    const domDate = document.getElementById('date');

    if (ele) {
      let temp = document.getElementById('text-signature');
      temp.id = 'new-text-signature';

      if (domElement.childNodes.length) {
        let removeTemp = document.getElementById('new-text-signature');
        domElement.removeChild(removeTemp);
      }

      domElement.appendChild(temp);
    }

    domProgramType.innerHTML = methods.watch('progTypeConsideration') ?? '';
    domRemark.innerHTML = methods.watch('remark') ?? '';
    domSecondaryBrand.innerHTML = methods.watch('secondaryBrand') ?? '';
    domCampaignType.innerHTML = methods.watch('campaignType') ?? '';
    domCampaignOutline.innerHTML = methods.watch('campaignOutline') ?? '';
    if (methods.watch('date')) domDate.innerHTML = convertDateFormat(methods.watch('date'));

    onClose();
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();

    setImageToElement(signatureData);

    onClose();
    setIsLoading(false);
  };

  const strToImg = () => {
    const temp = document.getElementById('text-signature');

    domtoimage
      .toPng(temp)
      .then(function (dataUrl) {
        setImageToElement(dataUrl, temp);
      })
      .catch(function (error) {
        enqueueSnackbar(AppConstants.SOMETHING_WENT_WRONG, 'error');
      })
      .finally(() => {
        onClose();
        setIsLoading(false);
      });
  };

  const handleSubmitSignIB = () => {
    // setIsLoading(true);
    setImageToElement('', true);
    // if (methods.watch('signatureType') === 'text') {
    //   strToImg();
    // } else {
    //   saveSignature();
    // }
    setState({
      signatureType: methods.watch('signatureType'),
      signatureText: methods.watch('signatureText'),
      signatureDrawing: methods.watch('signatureDrawing'),
      progTypeConsideration: methods.watch('progTypeConsideration'),
      remark: methods.watch('remark'),
      secondaryBrand: methods.watch('secondaryBrand'),
      campaignType: methods.watch('campaignType'),
      campaignOutline: methods.watch('campaignOutline'),
      date: methods.watch('date'),
    });

    handleIBSignData(
      true,
      methods.watch('remark'),
      methods.watch('progTypeConsideration'),
      convertDateFormat(methods.watch('date') || new Date()),
      methods.watch('secondaryBrand'),
      methods.watch('campaignType'),
      methods.watch('campaignOutline'),
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <FormProvider {...methods}>
        <Dialog
          fullBodyScroll={true}
          open={open}
          onClose={() => {
            methods.reset();
            onClose();
          }}
          headline={
            <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
              Sign IB
            </Typography>
          }
          description={
            <Box sx={{ width: '100%', minHeight: '60vh', textAlign: 'left' }}>
              <Typography color={theme.palette.text[100]} component="span" variant="Medium-16">
                Digital Signature Preview
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  background: '#F4F4F4',
                  height: methods.watch('signatureType') === 'text' ? '120px' : '200px',
                  marginTop: '5px',
                }}
              >
                <div className="text-sign-box" style={{ paddingTop: '10px' }}>
                  <div
                    id="text-signature"
                    key={'text-signature'}
                    style={{
                      fontFamily: 'Poppins',
                      textAlign: 'left',
                      maxWidth: '250px',
                      maxHeight: '100px',
                    }}
                  >
                    <div id="digital-sign">Digitally Signed By : </div>
                    <div>{userName}</div>
                    <div id="job-title">
                      Title: <span id="job-id">{'Planner'}</span>
                    </div>
                    <div id="date-time">
                      Date: <span id="date-id">{formatDate(new Date(), 'dd-MM-yyyy')}</span>
                    </div>
                  </div>
                </div>

                {/* <div style={{ textAlign: 'center' }}>
                  <Typography
                    variant="Regular-14"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    color={'#6D7581'}
                  >
                    Your preview here
                  </Typography>
                </div> */}
              </Box>

              <Box style={{ marginTop: '24px' }}>
                <Box sx={{ width: '100%', marginBottom: '20px', marginTop: '4px' }}>
                  <InputField
                    id="text-signature-input"
                    inputHeight="50px"
                    type="text"
                    label="Program Type Consideration"
                    placeholder="Add Program Type Consideration"
                    {...methods.register('progTypeConsideration')}
                  />
                </Box>
                <Box sx={{ width: '100%', marginTop: '4px', marginBottom: '20px' }}>
                  <InputField
                    id="remark"
                    inputHeight="50px"
                    type="text"
                    label="Remark"
                    placeholder="Add remark"
                    {...methods.register('remark')}
                  />
                </Box>
                <Box sx={{ width: '100%', marginTop: '4px', marginBottom: '20px' }}>
                  <InputField
                    id="secondary-brand"
                    inputHeight="50px"
                    type="text"
                    label="Secondary/Brand"
                    placeholder="Add secondary/brand"
                    {...methods.register('secondaryBrand')}
                  />
                </Box>

                <Box sx={{ width: '100%', marginTop: '4px', marginBottom: '20px' }}>
                  <InputField
                    id="campaign-type"
                    inputHeight="50px"
                    type="text"
                    label="Campaign Type"
                    placeholder="Add campaign type"
                    {...methods.register('campaignType')}
                  />
                </Box>
                <Box sx={{ width: '100%', marginTop: '4px', marginBottom: '20px' }}>
                  <InputField
                    id="campaign-outline"
                    inputHeight="50px"
                    type="text"
                    label="Campaign Outline"
                    placeholder="Add campaign outline"
                    {...methods.register('campaignOutline')}
                  />
                </Box>
                <Box sx={{ width: '100%', marginTop: '4px', marginBottom: '20px' }}>
                  <DatePicker
                    label="Date"
                    id="date"
                    placeholder="Select date"
                    textAlign="left"
                    {...methods.register('date')}
                    onChange={() => null}
                  />
                </Box>
              </Box>
            </Box>
          }
          buttons={
            <Button
              key="button-1"
              type="submit"
              variant="contained"
              label="Sign IB"
              onClick={handleSubmitSignIB}
              disabled={!methods.formState.isValid}
              height="40px"
            />
          }
          width="668px"
          backgroundColor="white"
        />
      </FormProvider>
    </>
  );
}
