import { downloadFile } from '../../services/file-download-service';

export const supportedFileTypes = ['pdf', 'jpg', 'jpeg', 'png', 'docx', 'docx', 'xls', 'xlsx'];

export const getFileType = fileName => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  return supportedFileTypes.includes(extension) ? extension : extension;
};

export const getMimeType = fileExtension => {
  switch (fileExtension) {
    case 'bmp':
      return 'image/bmp';
    case 'csv':
      return 'text/csv';
    case 'gif':
      return 'image/gif';
    case 'jpg':
      return 'image/jpg';
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'tiff':
      return 'image/tiff';
    case 'ppt':
      return 'application/vnd.ms-powerpoint';
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'doc':
      return 'application/msword';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'pdf':
      return 'application/pdf';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xlsm':
      return 'application/vnd.ms-excel.sheet.macroEnabled.12';
    default:
      return '';
  }
};

export const convertURLToFile = async (fileStorageUrl, fileName, fileExtension) => {
  try {
    return fetch(fileStorageUrl)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, {
          type: getMimeType(fileExtension),
        });
      });
  } catch (err) {
    return null;
  }
};

export const convertURLToArrayBuffer = async fileStorageUrl => {
  return downloadFile({ fileName: fileStorageUrl })
    .then(res => {
      if (res?.data) return res.data.arrayBuffer();
      return null;
    })
    .catch(error => {
      return error;
    });
};

export const convertURLToArrayBlob = async fileStorageUrl => {
  return fetch(fileStorageUrl)
    .then(res => {
      if (res) return res.blob();
      return null;
    })
    .catch(error => {
      return error;
    });
};

export const downloadFileFromBlob = (blob, fileName) => {
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFromURL = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleViewDocInNewTabClicked = (link, title) => {
  window.open(
    `/#/document-viewer?title=${encodeURIComponent(title)}&link=${encodeURIComponent(link)}`,
    '_blank',
    'noopener,noreferrer',
  );
};
