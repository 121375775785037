import React, { useCallback, useEffect, useState } from 'react';
import Button from '../buttons';
import { FilterJarIcon } from '../../../libs/svg-icons/icons';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import Dialog from '../dialog';
import FilterListing from './filterlisting';
import { fetchFilterData } from '../../../services/vendor-po-service';

export default function FilterComponent({
  filterState = {},
  setFilterState = () => {},
  entity = '',
}) {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptionData, setFilterOptionData] = useState([]);

  const handleFilterOpen = useCallback(() => {
    setFilterOpen(true);
  }, []);

  const handleFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const getFilterOptions = async () => {
    //api call to fetch filter options
    await fetchFilterData(entity)
      .then(res => {
        const { data } = res.data;
        console.log(data);
        setFilterOptionData(data);
        let d = {};
        data.forEach(x => {
          d[x?.label] = [];
        });
        setFilterState(d);
      })
      .catch(err => {
        console.log('Error fetching filter options: ', err);
      });
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        label="Filters"
        onClick={handleFilterOpen}
        startIcon={<FilterJarIcon fill={theme.palette.primary.main} />}
        sx={{ marginTop: '-5px', marginRight: '15px' }}
      />

      <Dialog
        open={filterOpen}
        onClose={handleFilterClose}
        headline={
          <Typography variant="SemiBold-22" alignItems="flex-start" justifyContent="flex-start">
            Filters
          </Typography>
        }
        description={
          isLoading ? (
            <div style={styles.centerDivItems}>
              <CircularProgress size={35} style={{ margin: '70px auto' }} />
            </div>
          ) : (
            <Box
              sx={{
                width: '100%',
                gap: '27px',
                height: '550px',
              }}
            >
              <FilterListing
                filterState={filterState}
                setFilterState={setFilterState}
                handleFilterClose={handleFilterClose}
                filterData={filterOptionData}
              />
            </Box>
          )
        }
        width="860px"
        showDialogActions={false}
      />
    </>
  );
}

const styles = {
  centerDivItems: {
    height: '300px',
    width: '100%',
    textAlign: 'center',
    paddingTop: '60px',
    display: 'flex',
    flexDirection: 'column',
  },
};

const filterResponse = {
  'status': 200,
  'data': [
    {
      'label': 'Month',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Year',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Agency',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Client',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Business Unit',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Category',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Brand',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Media Type',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Planner Name',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Variant (Finance) Code',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Variant (Finance) Name',
      'value': [],
      'totalCount': 0,
      'entity': 'MINDER_BB',
    },
    {
      'label': 'Accepted by Vendor',
      'value': [],
      'totalCount': 0,
      'entity': 'VENDOR_PO',
    },
    {
      'label': 'Submitted Invoice by Vendor',
      'value': [],
      'totalCount': 0,
      'entity': 'VENDOR_PO',
    },
  ],
  'timestamp': '2024-11-15T05:08:15.121+00:00',
};
