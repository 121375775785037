import RouteHandler from '../../components/router/handler';
import DashboardLayout from '../../layouts/dashboard';
import { Navigate } from 'react-router-dom';
import MediaPlansRouter from './MediaPlans';
import ProjectsRouter from './Projects';
import POClientRouter from './POClient';
import VendorsRouter from './Vendors';
import NotificationsRouter from './Notifications';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import VendorInvoiceRouter from './VendorInvoice';

const currentEnv = process.env.REACT_APP_ENV;

const AppRoutes = [
  {
    path: '',
    component: <Navigate to="media-plans" />,
  },
  {
    path: 'media-plans/*',
    component: <MediaPlansRouter />,
  },
  {
    path: 'projects/*',
    component: ['PROD'].includes(currentEnv) ? <>Under development..!!</> : <ProjectsRouter />,
  },
  {
    path: 'po-client/*',
    component: ['PROD', 'UAT'].includes(currentEnv) ? (
      <>Under development..!!</>
    ) : (
      <POClientRouter />
    ),
  },
  {
    path: 'vendors/*',
    component: ['PROD'].includes(currentEnv) ? <>Under development..!!</> : <VendorsRouter />,
  },
  {
    path: 'vendor-invoice/*',
    component: ['PROD', 'UAT'].includes(currentEnv) ? (
      <>Under development..!!</>
    ) : (
      <VendorInvoiceRouter />
    ),
  },
  {
    path: 'notifications/*',
    component: <NotificationsRouter />,
    // component: <>Notification coming soon..!!</>,
  },
];

const AppRouter = () => {
  const auth = useAuth();
  useEffect(() => {
    window.refreshToken = async () => {
      console.log('Refresh token');
      return auth
        .signinSilent()
        .then(user => {
          // console.log('Access token refreshed successfully', user);
          return user.access_token;
        })
        .catch(error => {
          console.error('Failed to refresh access token:', error);
          localStorage.clear();
          return auth.signoutRedirect();
        });
    };
  }, []);

  return <RouteHandler routes={AppRoutes} container={<DashboardLayout />} />;
};

export default AppRouter;
